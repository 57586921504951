import {
  Component,
  OnInit
} from '@angular/core';
import {
  ActivatedRoute,
  Router
} from '@angular/router';

@Component({
  selector: 'app-instagram-callback',
  templateUrl: './instagram-callback.component.html',
  styleUrls: ['./instagram-callback.component.scss']
})
export class InstagramCallbackComponent implements OnInit {
  public code: string;
  private token: string;

  constructor(private route: ActivatedRoute, private router: Router) {
  }

  async ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      this.code = params.get('code');

      this.route.fragment.subscribe((fragment: string) => {
        this.token = fragment.split('=')[1];
      });

      if (this.code) {
        localStorage.setItem('code', this.code);
      }

      if (this.token) {
        localStorage.setItem('token', this.token);
      }

      this.router.navigate(['/instagram']);
    });
  }
}
