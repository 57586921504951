import {
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { Photo } from '../../models';

@Component({
  selector: 'single-photo',
  templateUrl: './single-photo.component.html',
  styleUrls: ['./single-photo.component.scss']
})
export class SinglePhotoComponent {
  public isLoaded = false;

  @Input()
  public photo: Photo;

  public imageLoaded() {
    this.isLoaded = true;
  }
}
