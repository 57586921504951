import { Injectable } from '@angular/core';
import { SettingsService } from './settings/settings.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { HttpClient } from '@angular/common/http';
import { forEach } from 'lodash';

import {
  OrderPhoto,
  PhotoSource,
  UploadPhotoSettings,
} from './order';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private settingsService: SettingsService,
    private http: HttpClient) {
  }

  public async sendCroppedPhoto(
    croppedPhoto: ImageCroppedEvent | null,
    photoSource: string | null,
    orderId: string,
    photoToPush?: OrderPhoto): Promise<any> {
    const formData: FormData = new FormData();
    console.log('sendCroppedPhoto');

    if (croppedPhoto) {
      formData.append('file', croppedPhoto.file as Blob, photoSource + '.png'); // TODO: extension should not be hardcoded
    } else {
      formData.append('photo', JSON.stringify(photoToPush));
    }

    formData.append('orderId', orderId);
    return await this.http.post(`${this.settingsService.settings.apiUrl}/order/image`, formData).toPromise();
  }

  public async uploadPhoto(file, orderId: string): Promise<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.relativePath);
    formData.append('orderId', orderId);

    return this.http.post(`${this.settingsService.settings.apiUrl}/order/image`, formData).toPromise();
  }

  public async uploadPhotos(files: FileList, uploadSettings: UploadPhotoSettings): Promise<Array<string>> {
    const formData: FormData = new FormData();

    forEach(files, (file) => {
      formData.append('files', file, file.name);
    });

    forEach(uploadSettings, (value, key) => {
      formData.append(key, value);
    });

    return this.http.post<Array<string>>(`${this.settingsService.settings.apiUrl}/order/images`, formData).toPromise();
  }

  public async savePhotoFromUrl(url: string, source: PhotoSource, orderId: string, additionalPhotoData: any) {
    const formData: FormData = new FormData();

    formData.append('url', url);
    formData.append('source', source.toString());
    formData.append('orderId', orderId);

    if (additionalPhotoData) {
      formData.append('additionalPhotoData', JSON.stringify(additionalPhotoData));
    }

    return this.http.post<Array<string>>(`${this.settingsService.settings.apiUrl}/order/images`, formData)
      .toPromise();
  }

  public async getInstagramToken(code: string, redirectUrl: string): Promise<string> {
    return <Promise<string>>this.getRequest('auth/instagram/token', {
      code,
      redirect_uri: redirectUrl,
    });
  }

  public postRequest(endpoint: string, body?: any): Promise<any> {
    return this.http.post(`${this.settingsService.settings.apiUrl}/${endpoint}`, body || {}).toPromise();
  }

  public getRequest(endpoint: string, params?: { [param: string]: string | string[] }): Promise<any> {
    return this.http.get(`${this.settingsService.settings.apiUrl}/${endpoint}`, { params }).toPromise();
  }

  public patchRequest(endpoint: string, body: any): Promise<any> {
    return this.http.patch(`${this.settingsService.settings.apiUrl}/${endpoint}`, body || {}).toPromise();
  }

  public async refreshToken(refreshToken: string, provider: string): Promise<string> {
    const endpoint = `auth/${provider}/refresh/`;

    const newToken = await this.postRequest(endpoint, {refreshToken});

    return newToken.access_token;
  }
}
