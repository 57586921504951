import { Component } from '@angular/core';
import { OrderService } from './order.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent {
  constructor(public orderService: OrderService) {
  }

  public openMenu() {
    this.orderService.toggleSidebar();
  }

}

