import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InitSettingsService {

  constructor(private http: HttpClient) { }

  private static async overrideStaticConfigurations(configObject: JSON | any): Promise<JSON> {
    environment.apiUrl = !configObject.apiUrl || configObject.apiUrl === '${API_URL}'
      ? 'https://localhost:4000'
      : configObject.apiUrl;
    environment.shopUrl = !configObject.shopUrl || configObject.shopUrl === '${SHOP_URL}'
      ? 'http://localhost:8081'
      : configObject.shopUrl;
    environment.ga = !configObject.ga || configObject.ga === '${GA}'
      ? 'UA-37059035-5'
      : configObject.ga;
    environment.production = configObject.production === '' || configObject.production === '${PROD}'
      ? false // dev!
      : configObject.production;

    return environment;
  }

  public getSettings(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get('/assets/appConfig.json').toPromise().then(async (result) => {
        try {
          await InitSettingsService.overrideStaticConfigurations(result);
          resolve();
        } catch (e) {
          console.error(e);
          reject(e);
        }
      }).catch((error) => {
        console.error(error);
      });
    });
  }
}
