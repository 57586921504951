import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';
import { OrderService } from '../../order';
import {
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap';

@Component({
  selector: 'app-intro-page',
  templateUrl: './intro-page.component.html',
  styleUrls: ['./intro-page.component.scss'],
})
export class IntroPageComponent implements OnInit {
  modalRef: BsModalRef;
  orderIdFromUrl: string;

  @ViewChild('template', { static: true }) public templateref: TemplateRef<any>;

  constructor(
    private route: ActivatedRoute,
    private modalService: BsModalService,
    public orderService: OrderService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.orderIdFromUrl = this.route.snapshot.paramMap.get('orderId');

    if (this.orderIdFromUrl) {
      this.modalRef = this.modalService.show(this.templateref, { class: 'modal-md modal-dialog-centered ' });
    }
  }

  confirm() {
    localStorage.setItem('orderId', this.orderIdFromUrl);
    this.orderService.setOrder(this.orderIdFromUrl);
    this.router.navigate(['/']);

    this.modalRef.hide();
  }

  decline() {
    this.modalRef.hide();
  }
}
