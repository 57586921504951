import { BrowserModule } from '@angular/platform-browser';
import {
  APP_INITIALIZER,
  NgModule,
} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { CallbackPipe } from './utils/pipes/callback.pipe';
import { HeaderComponent } from './shared/header/header.component';
import { IntroPageComponent } from './shared/intro-page/intro-page.component';
import {
  OrderModule,
  OrderService,
} from './order';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ModalModule } from 'ngx-bootstrap';
import { SettingsComponent } from './settings';
import { ReactiveFormsModule } from '@angular/forms';
import { ApiService } from './api.service';
import { SettingsWidgetComponent } from './settings/settings-widget/settings-widget.component';
import { OrderProgressComponent } from './order/order-progress/order-progress.component';
import { InitSettingsService } from './app-init/init-settings.service';
import { SidebarModule } from './sidebar/sidebar.module';
import {
  FacebookModule,
  FacebookService,
} from 'ngx-facebook';
import { DragDropDirective } from './shared/directives/drag-drop.directive';
import { SharedModule } from './shared/shared.module';
import { environment } from '../environments/environment';
import { ImageBrowsingModule } from './image-browsing/image-browsing.module';

@NgModule({
  declarations: [
    AppComponent,
    CallbackPipe,
    HeaderComponent,
    IntroPageComponent,
    SettingsComponent,
    SettingsWidgetComponent,
    OrderProgressComponent,
    DragDropDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FacebookModule,
    OrderModule,
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    ModalModule.forRoot(),
    ReactiveFormsModule,
    SidebarModule,
    SharedModule,
    ImageBrowsingModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: InitSettingsServiceProvider,
      deps: [InitSettingsService],
      multi: true,
    },
    OrderService,
    ApiService,
    InitSettingsService,
    FacebookService
  ],
  exports: [
    DragDropDirective,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function InitSettingsServiceProvider(provider: InitSettingsService) {
  return () => provider.getSettings();
}
