import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { Album } from '../../models';

@Component({
  selector: 'single-album',
  templateUrl: './single-album.component.html',
  styleUrls: ['./single-album.component.scss'],
})
export class SingleAlbumComponent implements OnInit {
  public isLoaded = false;

  @Input()
  public album: Album;

  @Output()
  public photoClick: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
  }

  ngOnInit() {
  }

  public imageLoaded(event) {
    this.isLoaded = true;
  }
}
