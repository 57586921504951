import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar.component';
import { UsedPhotosComponent } from './used-photos/used-photos.component';
import { SourcePickerComponent } from './source-picker/source-picker.component';
// import { MyFacebookModule } from '../facebook/my-facebook.module';
import { SourceModule } from '../source/source.module';
import { UploadComponent } from './upload/upload.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { SharedModule } from '../shared/shared.module';
import { InstagramModule } from '../instagram/instagram.module';
import { ImageBrowsingModule } from '../image-browsing/image-browsing.module';
import { SocialConnectModule } from '../social-connect/social-connect.module';
import { SocialPickerComponent } from './social-picker/social-picker.component';
import { ExistingPhotosComponent } from './existing-photos/existing-photos.component';
import { AddByUrlComponent } from './add-by-url/add-by-url.component';
import { SidebarHeaderComponent } from './sidebar-header/sidebar-header.component';

@NgModule({
  declarations: [
    SidebarComponent,
    UsedPhotosComponent,
    SourcePickerComponent,
    UploadComponent,
    SocialPickerComponent,
    ExistingPhotosComponent,
    AddByUrlComponent,
    SidebarHeaderComponent
  ],
  exports: [
    SidebarComponent,
  ],
  imports: [
    CommonModule,
    // MyFacebookModule,
    InstagramModule,
    SourceModule,
    NgxFileDropModule,
    SharedModule,
    ImageBrowsingModule,
    SocialConnectModule,
  ],
})
export class SidebarModule { }
