import { NgModule } from '@angular/core';
import { MiniPreviewComponent } from './mini-preview/mini-preview.component';
import { LoaderComponent } from './loader/loader.component';

@NgModule({
  declarations: [
    MiniPreviewComponent,
    LoaderComponent
  ],
  exports: [
    MiniPreviewComponent,
    LoaderComponent
  ],
  imports: [],
})
export class SharedModule {}
