import { Injectable } from '@angular/core';
import { Settings } from './settings';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  settings: Settings = {
    apiUrl: null,
  };

  constructor() {
    const loadedSettings = JSON.parse(localStorage.getItem('appSettings'));

    if (environment.apiUrl === '${API_URL}') {
      throw new Error('ni pravilen apiUrl');
    }

    if (environment.shopUrl === '${SHOP_URL}') {
      throw new Error('ni pravilen shopUrl');
    }

    if (!loadedSettings) {
      this.settings.apiUrl = environment.apiUrl;
      this.settings.shopUrl = environment.shopUrl;
    } else {
      this.settings = loadedSettings;
    }

    this.settings.ga = environment.ga;
  }

  public getSettings() {
    return this.settings;
  }

  public get(settingName: string): string {
    try {
      return this.settings[settingName];
    } catch (error) {
      console.error(error);
    }
  }

  public async save(settingsData: Settings): Promise<any> {
    localStorage.setItem('appSettings', JSON.stringify(settingsData));
    this.settings = settingsData;
    return;
  }
}
