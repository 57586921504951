import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MailUploadComponent } from './mail-upload/mail-upload.component';



@NgModule({
  declarations: [MailUploadComponent],
  exports: [
    MailUploadComponent,
  ],
  imports: [
    CommonModule,
  ],
})
export class SourceModule { }
