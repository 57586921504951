import { Injectable } from '@angular/core';
import { SettingsService } from '../settings';
import { HttpClient } from '@angular/common/http';
import {
  filter,
  isNil,
  map,
} from 'lodash';

import {
  Album,
  Photo,
} from '../image-browsing/models';

@Injectable({
  providedIn: 'root',
})
export class InstagramService {
  public isConnected = false;
  public albums: Array<Album>;
  public photos: Array<Photo>;

  private accessTokenLocalStorageKey = 'iaccess';
  private intervalHandle: number;
  private userData: any;

  constructor(private settingsService: SettingsService,
              private http: HttpClient) {
    this.isConnected = !isNil(localStorage.getItem(this.accessTokenLocalStorageKey));
  }

  private static createOauthWindow(url: string,
                                   name = 'Authorization') {
    if (url == null) {
      return null;
    }

    return window.open(url, name);
  }

  public async login() {
    // InstagramService.createOauthWindow(`${this.settingsService.settings.apiUrl}/auth/instagram?callback=${this.settingsService.settings.apiUrl}/auth/callback/instagram`);
    InstagramService.createOauthWindow(`${this.settingsService.settings.apiUrl}/auth/instagram`);

    return new Promise((resolve) => {
      if (this.intervalHandle) {
        return;
      }

      this.intervalHandle = setInterval(() => {
        console.log('waiting for oAuth ...');
        if (localStorage.getItem('isitokensaved') === '1') {
          localStorage.removeItem('isitokensaved');
          this.isConnected = true;
          clearInterval(this.intervalHandle);
          this.intervalHandle = undefined;

          resolve();
        }
      }, 1000);
    });
  }

  public async getMedia(): Promise<void> {
    const url = `/me/media?fields=id,caption,media_type,media_url,children{id,media_type,media_url}`;

    const response = await this.get(url);
    this.photos = this.mapInstagramAlbums(filter(response.data, (item) => {
      return item.media_type === 'IMAGE' || item.media_type === 'CAROUSEL_ALBUM';
    }));

    return;
  }

  public async getUser(): Promise<any> {
    this.userData = await this.get('https://www.googleapis.com/oauth2/v1/userinfo', true);
    return this.userData;
  }

  private async get(method: string, fullUrl = false): Promise<any> {
    const token = localStorage.getItem(this.accessTokenLocalStorageKey);

    if (!token) {
      await this.login();
      return this.get(method, fullUrl);
    }

    // let headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    let url = fullUrl ? method : `https://graph.instagram.com/${method}`;

    url = url + `&access_token=${token}`;

    return this.http.get(url)
      .toPromise()
      .catch(async (error) => {
        if (error.status === 400 && error.error.error.code === 190) { // token expired or no token
          try {
            await this.login();

            // return this.get(method);
            console.log('Instagram token expired ... ');
          }
          catch (error) {
            console.error('error updating token.', error);
            return false;
          }
        }
      });
  }

  private mapInstagramAlbums(albums: any): Array<Photo> {
    return map(albums, (album) => {
      return {
        id: album.id,
        url: album.media_url,
        originalUrl: album.media_url,
        isAlbum: album.media_type === 'CAROUSEL_ALBUM',
      };
    });
  }
}
