import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
  PopoverModule,
  TooltipModule,
} from 'ngx-bootstrap';
import {
  ImageCropperComponent,
  ImageCropperModule
} from 'ngx-image-cropper';

import { OrderComponent } from './order.component';
import { ModalCropperComponent } from './modal-cropper/modal-cropper.component';
import { PhotoPreviewComponent } from './photo-preview/photo-preview.component';
import { UserDataComponent } from './user-data/user-data.component';
import { PaymentComponent } from './payment/payment.component';

@NgModule({
  declarations: [
    OrderComponent,
    PhotoPreviewComponent,
    ModalCropperComponent,
    UserDataComponent,
    PaymentComponent
  ],
  exports: [
    OrderComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    PopoverModule,
    ImageCropperModule,
    TooltipModule
  ],
  entryComponents: [ImageCropperComponent, ModalCropperComponent]
})
export class OrderModule { }
