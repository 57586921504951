import {
  Component,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { Settings } from './settings';
import { SettingsService } from './settings.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  public settingsForm: FormGroup;
  public settings: Settings;

  constructor(private formBuilder: FormBuilder, private settingsService: SettingsService) {
  }

  ngOnInit() {
    this.settings = this.settingsService.getSettings();

    this.settingsForm = this.formBuilder.group({
      apiUrl: this.settings && this.settings.apiUrl ? this.settings.apiUrl : 'http://localhost:4000',
      shopUrl: this.settings && this.settings.shopUrl ? this.settings.shopUrl : 'http://localhost:8081',
      facebookAppId: this.settings && this.settings.facebookAppId ? this.settings.facebookAppId : '',
    });
  }

  public async onSubmit(settingsData: Settings) {
    await this.settingsService.save(settingsData);
    this.settings = settingsData;
  }
}
