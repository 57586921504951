import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import {
  FacebookService,
  GooglePhotosService,
  InstagramService,
  SocialProviders
} from '../../social-connect';

@Component({
  selector: 'social-picker',
  templateUrl: './social-picker.component.html',
  styleUrls: ['./social-picker.component.scss']
})
export class SocialPickerComponent {
  @Input()
  public set isWide(isWide) {
    this.buttonsClass = isWide ? 'col-4' : 'col-10 offset-1';
  };

  @Output()
  public socialButtonClick = new EventEmitter<SocialProviders>();

  public socialProviders = SocialProviders;
  public buttonsClass = '';

  constructor(public googlePhotosService: GooglePhotosService,
              public facebookService: FacebookService,
              public instagramService: InstagramService) { }
}
