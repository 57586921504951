import { Injectable } from '@angular/core';
import { remove } from 'lodash';
import { ImageCroppedEvent } from 'ngx-image-cropper';

import { PhotoSource } from './photo-source.enum';
import { ApiService } from '../api.service';

import * as moment from 'moment';
import { Order } from './';
import { Subject } from 'rxjs';

moment.locale('sl');

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  public order: Order;
  public orderId: string;
  public style: string;
  public photoSlots: number;
  public uniquePhotos: Array<any>;
  public minimumOrder: number = 12;

  public toggleSidebar$ = new Subject();

  constructor(
    private apiService: ApiService) {
    this.getOrderId()
      .then(async (orderId: string) => {
        await this.setOrder(orderId);
      })
      .catch((err) => {
        console.error('error: ', err);
      });
  }

  public async setOrder(orderId: string): Promise<void> {
    this.orderId = orderId;

    try {
      this.order = await this.getOrder(this.orderId);
      this.countPhotoSlots();

      return;
    }
    catch (error) {
      return this.setOrder(await this.createNewOrder());
    }
  }

  public async addPhoto(photo: any, source: PhotoSource) {
    // const photoToPush: OrderPhoto = {
    //   urn: `facebook:${photo.id}`,
    //   url: photo.images[0].source,
    //   date: moment(photo.created_time).format('DD. MM. YYYY'),
    //   comment: photo.name,
    // };

    let photoUrl = '';

    if (source === PhotoSource.Url) {
      photoUrl = photo;
    }
    else if (source === PhotoSource.GooglePhotos) {
      photoUrl = photo;
    }
    else {
      photoUrl = photo.images[0].source;
    }

    const postPhotoResponse = await this.apiService.savePhotoFromUrl(photoUrl, source, this.orderId, photo);

    this.setOrder(this.orderId);

    // const savePhotoResponse = await this.apiService.sendCroppedPhoto(null, null, this.orderId, photoToPush);
    // photoToPush.croppedPhotoFilename = savePhotoResponse.filename;
    //
    // if (source === PhotoSource.Facebook) {
    //   this.order.photos.push(photoToPush);
    // }
    // await this.saveOrder();
    // this.countPhotoSlots();

  }

  public async removePhoto(urn: string) {
    remove(this.order.photos, { urn });
    await this.saveOrder();
    this.countPhotoSlots();
  }

  public async saveOrder(changedPhoto?: string): Promise<void> {
    // localStorage.setItem('active-order', JSON.stringify({ photos: this.order.photos }));
    await this.apiService.patchRequest(`order/${this.orderId}`, {...this.order, changedPhoto});
    return;
  }

  public async sendCroppedPhoto(imageCroppedEvent: ImageCroppedEvent, photoUrn: string): Promise<any> {
    return await this.apiService.sendCroppedPhoto(imageCroppedEvent, photoUrn, this.orderId);
  }

  public async getOrderId(): Promise<string> {
    const orderId = localStorage.getItem('orderId');

    if (orderId) {
      return orderId;
    }

    return await this.createNewOrder();
  }

  public async createNewOrder(): Promise<string> {
    const newOrderId = await this.apiService.postRequest('order');
    localStorage.setItem('orderId', newOrderId);

    return newOrderId;
  }

  public async getOrder(orderId: string): Promise<Order> {
    const order = await this.apiService.getRequest(`order/${orderId}`).catch((error) => {
      console.error(error);
    });

    return order;
  }

  public async uploadPhoto(fileList: FileList, orderSettings: UploadPhotoSettings) {

    return this.apiService.uploadPhotos(fileList, orderSettings);

    // const uploadResponse = await this.apiService.uploadPhoto(file, orderId);
    // const photoToPush: OrderPhoto = {
    //   urn: `upload:${uploadResponse.filename}`,
    //   croppedPhotoFilename: uploadResponse.filename,
    //   date: moment().format('DD. MM. YYYY'),
    //   comment: uploadResponse.filename,
    // };
    // const savePhotoResponse = await this.apiService.sendCroppedPhoto(null, null, this.orderId, photoToPush);
    // photoToPush.croppedPhotoFilename = savePhotoResponse.filename;
    //
    // this.order.photos.push(photoToPush);
    // await this.saveOrder();
    // this.countPhotoSlots();
  }

  public getUniquePhotos() {
    const uniquePhotos: any = {};

    this.order.photos.forEach((photo) => {
      const previousCount = uniquePhotos[photo.urn] ? uniquePhotos[photo.urn].count : 0;
      uniquePhotos[photo.urn] = {
        ...photo,
        count: previousCount + 1,
      };
    });

    this.uniquePhotos = uniquePhotos;
  }

  private countPhotoSlots() {
    this.photoSlots = Math.ceil(this.order.photos.length / this.minimumOrder) * this.minimumOrder;
    this.getUniquePhotos();
  }

  public toggleSidebar() {
    this.toggleSidebar$.next();
  }
}


export interface UploadPhotoSettings {
  orderId: string;
  shortId?: string;
  source: PhotoSource;
}
