import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  OrderService,
  PhotoSource,
} from '../../order';

@Component({
  selector: 'add-by-url',
  templateUrl: './add-by-url.component.html',
  styleUrls: ['./add-by-url.component.scss']
})
export class AddByUrlComponent {
  @Input()
  public mainTitle: string;

  @Output()
  public clickBack = new EventEmitter<void>();

  public url: string;

  constructor(private orderService: OrderService) {}

  public onChange($event) {
    this.url = $event.target.value;
  }

  public async onButtonClick() {
    await this.orderService.addPhoto(this.url, PhotoSource.Url);
  }
}
