import { Component } from '@angular/core';

import { FacebookService } from 'ngx-facebook';

import {
  Settings,
  SettingsService,
} from './settings';
import { OrderService } from './order';
import { Router, NavigationEnd } from '@angular/router';

// tslint:disable-next-line:ban-types
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public isSidebarVisible = false;
  private settings: Settings;

  constructor(
    private settingsService: SettingsService,
    public fb: FacebookService,
    public orderService: OrderService,
    public router: Router) {
    this.settings = this.settingsService.getSettings();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', this.settings.ga, {
          page_path: event.urlAfterRedirects
        });
      }
    });

    this.orderService.toggleSidebar$.subscribe(() => {
    this.onSidebarToggle();
  });
  }

  public onSidebarToggle() {
  this.isSidebarVisible = !this.isSidebarVisible;
}
}
