import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  OrderService,
  PhotoSource,
} from '../../order';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent {
  @Input()
  public orderId: string;

  @Input()
  public mainTitle: string;

  @Output()
  public clickBack = new EventEmitter<void>();

  public files: any = [];
  public uploadedFiles: Array<string> = [];

  constructor(private orderService: OrderService) {
  }

  allowDrop(ev) {
    ev.preventDefault();
  }

  async drop(event) {
    event.preventDefault();

    if (!event.dataTransfer.files.length) {
      return;
    }

    this.uploadFiles(event.dataTransfer.files);
  }

  async upload(event) {
    await this.uploadFiles(event);
  }

  delete(event) {
    console.log('delete', event);
  }

  private async uploadFiles(files: FileList) {
    const uploadResponse: Array<string> = await this.orderService.uploadPhoto(
      files,
      {
        orderId: this.orderId,
        source: PhotoSource.Upload,
      });

    this.uploadedFiles = [...this.uploadedFiles, ...uploadResponse];
    this.orderService.setOrder(this.orderId);
  }
}
