import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  Album,
  Photo,
  UserInfo,
} from '../../models';

@Component({
  selector: 'image-browser',
  templateUrl: './image-browser.component.html',
  styleUrls: ['./image-browser.component.scss'],
})
export class ImageBrowserComponent {
  @Input()
  public col = 'col-6 col-md-4 col-lg-2 mb-4';

  @Input()
  public colAlbums = 'col-12 col-sm-6 col-sm-6 col-md-4 col-lg-3 mb-4';

  @Input()
  public albums: Array<Album>;

  @Input()
  public albumPhotos: Array<Photo>;

  @Input()
  public userInfo: UserInfo;

  @Input()
  public showAlbums = false;

  @Input()
  public mainTitle;

  @Output()
  public clickAlbum = new EventEmitter<string>();

  @Output()
  public clickPhoto = new EventEmitter<Photo>();

  @Output()
  public clickUserInfo = new EventEmitter<void>();

  @Output()
  public clickBack = new EventEmitter<void>();

  public onAlbumClick(albumId: string) {
    this.clickAlbum.emit(albumId);
    this.showAlbums = false;
  }

  public onPhotoClick(photo: Photo) {
    photo.photoUsageCount = !photo.photoUsageCount ? 1 :photo.photoUsageCount + 1;

    this.clickPhoto.emit(photo);

  }

  public onUserInfoClick() {
    if (confirm('Odjavi?')) {
      this.clickUserInfo.emit();
    }
  }
}
