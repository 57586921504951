import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import {
  isNil,
  map,
} from 'lodash';

import { SettingsService } from '../settings';
import { ApiService } from '../api.service';
import {
  Album,
  Photo,
} from '../image-browsing/models';

@Injectable({
  providedIn: 'root',
})
export class FacebookService {
  public isConnected = false;
  public albums: Array<Album>;
  public photos: Array<Photo>;

  private accessTokenLocalStorageKey = 'faccess';
  private intervalHandle: number;

  constructor(private settingsService: SettingsService,
              private http: HttpClient) {
    this.isConnected = !isNil(localStorage.getItem(this.accessTokenLocalStorageKey));
  }

  private static createOauthWindow(url: string,
                                   name = 'Authorization') {
    if (url == null) {
      return null;
    }

    return window.open(url, name);
  }

  public mapAlbums(albums?: any): Array<Album> {
    return map(albums.data, (album) => {
      return {
        id: album.id,
        name: album.name,
        coverPhoto: album.cover_photo.images[0].source,
      };
    });
  }

  public async login() {
    FacebookService.createOauthWindow(`${this.settingsService.settings.apiUrl}/auth/facebook?callback=${this.settingsService.settings.apiUrl}/auth/callback/facebook`);

    return new Promise((resolve) => {
      if (this.intervalHandle) {
        return;
      }

      this.intervalHandle = setInterval(() => {
        console.log('waiting for oAuth ...');
        if (localStorage.getItem('isftokensaved') === '1') {
          localStorage.removeItem('isftokensaved');
          this.isConnected = true;
          clearInterval(this.intervalHandle);
          this.intervalHandle = undefined;

          resolve();
        }
      }, 1000);
    });
  }

  public async getAlbums() {
    const url = `https://graph.facebook.com/v6.0/me` +
      '?fields=albums{name,cover_photo{images},count,id}';

    const response = await this.get(url, true);

    return response.albums;
  }

  public async getAlbum(albumId: string) {
    const url = `https://graph.facebook.com/v6.0/${albumId}/photos` +
      '?fields=images,created_time,name';

    const response = await this.get(url, true);

    return FacebookService.mapAlbumPhotos(response.data);
  }

  private async get(method: string, fullUrl = false): Promise<any> {
    const token = localStorage.getItem(this.accessTokenLocalStorageKey);

    if (!token) {
      await this.login();
      return this.get(method, fullUrl);
    }

    // let headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    let url = fullUrl ? method : `https://graph.facebook.com/v6.0/me/${method}`;
    url = url + `&access_token=${token}`;

    return this.http.get(url)
      .toPromise()
      .catch(async (error) => {
        if (error.status === 401) { // token expired or no token
          try {
            // await this.updateToken();
            await this.login();

            return this.get(method);
          }
          catch (error) {
            console.error('error updating token.', error);
            return false;
          }
        }
      });
  }

  private async post(method: string, body): Promise<any> {
    const token = localStorage.getItem(this.accessTokenLocalStorageKey);

    if (!token) {
      await this.login();
      return this.get(method);
    }

    let headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.post(`https://photoslibrary.googleapis.com/v1/${method}`, body, { headers })
      .toPromise()
      .catch(async (error) => {
        if (error.status === 401) { // token expired
          try {
            // await this.updateToken();

            return this.get(method);
          }
          catch (error) {
            console.error('error updating token.', error);
            return false;
          }
        }
      });
  }

  public static mapAlbumPhotos(photos: any): Array<Photo> {
    return map(photos, (photo) => {
      return {
        id: photo.id,
        url: photo.images[0].source,
        // url: photo.images[photo.images.length-1].source,
        originalUrl: photo.images[0].source
      }
    })
  }
}
