import {
  Component,
  OnInit,
} from '@angular/core';
import { OrderService } from '../../order';
import { SettingsService } from '../../settings';

@Component({
  selector: 'existing-photos',
  templateUrl: './existing-photos.component.html',
  styleUrls: ['./existing-photos.component.scss'],
})
export class ExistingPhotosComponent implements OnInit {

  constructor(public orderService: OrderService, public settingsService: SettingsService) {
  }

  ngOnInit() {
  }

}
