import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { SocialProviders } from '../../social-connect';

@Component({
  selector: 'app-source-picker',
  templateUrl: './source-picker.component.html',
  styleUrls: ['./source-picker.component.scss'],
})
export class SourcePickerComponent {
  @Input()
  public showSinglePickers: boolean;

  @Input()
  public showSourcePicker: boolean;

  @Input()
  public orderPhotosCount: number;

  @Output()
  public setSource = new EventEmitter<SocialProviders | string>();

  public onSetSource(source: SocialProviders | string) {
    this.setSource.emit(source);
  }
}
