import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-mail-upload',
  templateUrl: './mail-upload.component.html',
  styleUrls: ['./mail-upload.component.scss']
})
export class MailUploadComponent implements OnInit {
  @Input()
  public orderId: string;

  @Input()
  public mainTitle: string;

  @Output()
  public clickBack = new EventEmitter<void>();

  public mailDomain = 'upload.instaprint.si';
  public emailId: string;

  constructor() {
  }

  ngOnInit() {
    this.emailId = `${this.orderId.substr(0,3)}-${this.orderId.substr(3,3)}`;
  }
}
