import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthResolveService implements CanActivate {
  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    if (route.params.provider === 'google') {
      const tokenInfo = JSON.parse(atob(route.queryParams.token));

      localStorage.setItem('gaccess', tokenInfo.access_token);
      localStorage.setItem('grefresh', tokenInfo.refresh_token);
      localStorage.setItem('isgtokensaved', '1');
    }
    else if (route.params.provider === 'facebook') {
      const tokenInfo = JSON.parse(atob(route.queryParams.token));

      localStorage.setItem('faccess', tokenInfo.access_token);
      localStorage.setItem('isftokensaved', '1');
    }
    else if (route.params.provider === 'instagram') {
      const tokenInfo = JSON.parse(atob(route.queryParams.token));

      localStorage.setItem('iaccess', tokenInfo.access_token);
      localStorage.setItem('isitokensaved', '1');
    }

    window.close();
    return;
  }
}
