import {
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { BsModalService } from 'ngx-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';

import { OrderService } from '../order.service';
import { ModalCropperComponent } from '../modal-cropper/modal-cropper.component';
import { SettingsService } from '../../settings/settings.service';
import { OrderPhoto } from '../';
import * as moment from 'moment';

@Component({
  selector: 'app-photo-preview',
  templateUrl: './photo-preview.component.html',
  styleUrls: ['./photo-preview.component.scss'],
})
export class PhotoPreviewComponent implements OnInit {
  @Input()
  public photo: OrderPhoto;

  public isEditingCrop = false;

  public croppedImage;
  public subscription;
  public dimensions: string = '300x300';
  public photoQuality: string = '60';

  private timeout: number;

  constructor(private orderService: OrderService,
              private modalService: BsModalService,
              private settingsService: SettingsService) {
  }

  async ngOnInit() {
    const photoUrl = `${this.settingsService.settings.apiUrl}/order/${this.orderService.orderId}/${this.photo.urn}/cropped/${this.dimensions}/${this.photoQuality}`;
    await this.setCropperImage(photoUrl);
  }

  public deletePhoto() {
    this.orderService.removePhoto(this.photo.urn);
  }

  async setCropperImage(url: string): Promise<void> {
    this.croppedImage = url;
    return;
  }

  public async openCropEdit() {
    this.isEditingCrop = true;

    const cropperPositionOriginal = this.photo.crop ? this.photo.crop.cropperPosition : null;

    const
      result: any = await fetch(`${this.settingsService.settings.apiUrl}/order/${this.orderService.orderId}/${this.photo.urn}/original`),
      blob = await result.blob(),
      reader = new FileReader();

    reader.readAsDataURL(blob);

    reader.onload = () => {
      this.subscription = this
        .modalService
        .show(ModalCropperComponent, {
          initialState: {
            originalImageBase64: reader.result,
            urn: this.photo.urn,
            cropperPositionOriginal,
          },
          ignoreBackdropClick: true,
          class: 'modal-lg modal-dialog-centered',
        })
        .content
        .onClose
        .subscribe(async (result) => {
          if (result) {
            await this.onCropSave(result);
          }
        });
    };

  }

  public async onCropSave(imageCroppedEvent: ImageCroppedEvent) {
    // const uploadedPhotoResponse = await this.orderService.sendCroppedPhoto(imageCroppedEvent, this.photo.urn);

    if (imageCroppedEvent) {
      delete imageCroppedEvent.base64;
      delete imageCroppedEvent.width;
      delete imageCroppedEvent.height;
      delete imageCroppedEvent.file;

      this.photo.crop = imageCroppedEvent;

      this.orderService.saveOrder(this.photo.urn).then(() => {
        this.croppedImage = `${this.settingsService.settings.apiUrl}/order/${this.orderService.orderId}/${this.photo.urn}/cropped/?a=${moment()
          .millisecond()}`;
      }).catch((error) => {
        console.error('Not saved!', this.photo.urn, error);
      });
    }

    this.subscription.unsubscribe();
    return;
  }

  public onPhotoTextChange(which: string, content) {
    clearTimeout(this.timeout);

    this.timeout = setTimeout((a) => {
      this.orderService.saveOrder();

    }, 2000);
  }
}
