import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes
} from '@angular/router';
import {
  InstagramComponent
} from './instagram';
import { IntroPageComponent } from './shared/intro-page/intro-page.component';
import { SettingsComponent } from './settings/settings.component';
import { RoutingService } from './routing.service';
import { InstagramResolverService } from './instagram-resolver.service';
import { AuthResolveService } from './auth-resolve.service';

const routes: Routes = [
  {
    path: '',
    component: IntroPageComponent,
  },
  {
    path: 'order/:orderId',
    component: IntroPageComponent
  },
  {
    path: 'settings',
    component: SettingsComponent
  },
  // {
  //   path: 'connect',
  //   component: ConnectComponent
  // },
  {
    path: 'instagram',
    component: InstagramComponent
  },
  {
    path: 'instagram/redirect',
    component: IntroPageComponent,
    canActivate: [InstagramResolverService]
  },
  {
    path: 'new/:newOrderParam',
    component: IntroPageComponent,
    resolve: {
      data: RoutingService
    }
  },
  {
    path: ':newOrderParam',
    component: IntroPageComponent,
    resolve: {
      data: RoutingService
    }
  },
  {
    path: 'auth/callback/:provider',
    component: IntroPageComponent,
    canActivate: [AuthResolveService],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
