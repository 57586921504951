import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';

import { ImageBrowsingService } from './image-browsing.service';
import { SingleAlbumComponent } from './components/single-album/single-album.component';
import { UserInfoComponent } from './components/user-info/user-info.component';
import { SinglePhotoComponent } from './components/single-photo/single-photo.component';
import { ImageBrowserComponent } from './components/image-browser/image-browser.component';

@NgModule({
  providers: [
    ImageBrowsingService
  ],
  declarations: [
    SingleAlbumComponent,
    SinglePhotoComponent,
    UserInfoComponent,
    ImageBrowserComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [
    SinglePhotoComponent,
    SingleAlbumComponent,
    UserInfoComponent,
    ImageBrowserComponent,
  ],
})
export class ImageBrowsingModule {}
