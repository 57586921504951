import {
  Component,
  OnInit
} from '@angular/core';

import { InstagramService } from './instagram.service';

@Component({
  selector: 'app-instagram',
  templateUrl: './instagram.component.html',
  styleUrls: ['./instagram.component.scss']
})
export class InstagramComponent implements OnInit {
  private loginStatus: string;
  public media: Array<any>;
  public instagramUser: any;

  constructor(private instagramService: InstagramService) {
  }

  ngOnInit() {
    this.instagramService.login();
    // this.getMedia().then((media) => {
    //   this.media = media;
    // });
  }

  // private async getMedia(take: number = 20, skip: number = 0) {
  //   return (await this.instagramService.getMedia()).data;
  // }

  public toggleImage(singleMedia: any, index: number) {
    console.log(index, singleMedia);
    this.media[index].isSelected = !this.media[index].isSelected;
  }

  public showSelectedOnly(media: any) {
    console.log('showSelectedOnly', media.isSelected);
    return media.isSelected;
  }
}
