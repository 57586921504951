import {
  Component,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { Location } from '@angular/common';
import { OrderService } from '../../order';
import { SettingsService } from '../../settings/settings.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output()
  sidebarToggle = new EventEmitter<void>();

  constructor(private location: Location, public orderService: OrderService, private settings: SettingsService) {
  }

  ngOnInit() {
  }

  public clickBack() {
    this.location.back();
  }

  public addPhotosClick() {

  }

  public async completeOrder(): Promise<any> {
    // return await this.orderService.completeOrder();
    window.location.href = `${this.settings.settings.shopUrl}/cart/`
    + `?add-to-cart=11`
    + `&photoOrderId=${this.orderService.orderId}`
    + `&quantity=${Math.ceil(this.orderService.photoSlots / this.orderService.minimumOrder)}`;
  }

  public onSidebarToggle() {
    console.log('toggle');
    this.sidebarToggle.emit();
  }

  public resetOrder() {
    if (confirm('Pobriši cel order?')) {
      localStorage.clear();

      window.location.reload();
    }
  }
}
