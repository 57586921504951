import { Component } from '@angular/core';
import { OrderService } from '../order.service';

@Component({
  selector: 'order-progress',
  templateUrl: './order-progress.component.html',
  styleUrls: ['./order-progress.component.scss']
})
export class OrderProgressComponent {
  constructor(public orderService: OrderService) {
  }
}
