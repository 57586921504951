import {
  Component,
  EventEmitter,
  HostBinding,
  Output,
} from '@angular/core';
import {
  OrderService,
  PhotoSource,
} from '../order';
import { SettingsService } from '../settings';
import {
  Album,
  Photo,
  UserInfo,
} from '../image-browsing/models';
import {
  FacebookService,
  GooglePhotosService,
  InstagramService,
  SocialProviders,
} from '../social-connect';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  public tab: string | SocialProviders = 'user-photos';
  public albumId: string;
  public showImageBrowser = false;

  public albums: Array<Album>;
  public photos: Array<Photo>;
  public userInfo: UserInfo; //TODO: strong typing!
  public showAlbums = false;
  public socialProviders = SocialProviders;
  public subtitle: string;

  @Output()
  public toggleSidebar = new EventEmitter<void>();

  @HostBinding('class.wide')
  public isWide = false;

  constructor(
    public orderService: OrderService,
    public settingsService: SettingsService,
    private googlePhotosService: GooglePhotosService,
    private facebookService: FacebookService,
    private instagramService: InstagramService
  ) {
    // console.log(this.orderService.getDifferentPhotos());
  }

  public setTab(tab: string) {
    this.tab = tab;
    this.isWide = false;
  }

  public async setSource(source: SocialProviders | string) {
    this.tab = source;
    this.isWide = true;
    this.albums = undefined;
    this.photos = undefined;

    if (source === SocialProviders.GooglePhotos) {
      this.showImageBrowser = true;

      this.googlePhotosService.mapAlbums(await this.googlePhotosService.getAlbums())
        .then((albums) => {
          this.albums = albums;
        });

      this.googlePhotosService.getUser().then(() => {
        this.userInfo = this.googlePhotosService.userInfo;
      });

      return;
    }
    else if (source === SocialProviders.Facebook) {
      this.showImageBrowser = true;
      this.albums = this.facebookService.mapAlbums(await this.facebookService.getAlbums());

      return;
    }
    else if (source === SocialProviders.Instagram) {
      this.showImageBrowser = true;
      this.photos = this.instagramService.photos; // TODO: make this as other service providers

      return;
    }

    this.showImageBrowser = false;
  }

  public async onClickAlbum(albumId: string) {
    if (this.tab === SocialProviders.GooglePhotos) {
      this.photos = await this.googlePhotosService.getAlbum(albumId);
    }
    else if (this.tab === SocialProviders.Facebook) {
      this.photos = await this.facebookService.getAlbum(albumId);
    }
  }

  public async onClickPhoto(photo: Photo) {
    await this.orderService.addPhoto(`${photo.originalUrl}`, PhotoSource.GooglePhotos);
  }

  public async onUserInfoClick() {
    if (this.tab === SocialProviders.GooglePhotos) {
      await this.googlePhotosService.logout();
      this.showImageBrowser = false;
    }

    this.setTab('user-photos');
  }

  public async onSocialButtonClick(source) {
    this.showAlbums = true;
    this.tab = source;
    this.subtitle = '---';

    if (source === SocialProviders.GooglePhotos) {
      this.subtitle = 'Google Photos';
      await this.googlePhotosService.getAlbums();

    }
    else if (source === SocialProviders.Facebook) {
      this.subtitle = 'Facebook';
      await this.facebookService.getAlbums();

    }
    else if (source === SocialProviders.Instagram) {
        this.subtitle = 'Instagram';
        this.showAlbums = false;
        await this.instagramService.getMedia();
    }
    else if (source === SocialProviders.Upload) {
      this.tab = SocialProviders.Upload;
      this.subtitle = 'Instagram';
    }
    else if (source === SocialProviders.Url) {
      this.tab = SocialProviders.Url;
      this.subtitle = 'Url povezava';
    }
    else if (source === SocialProviders.Mail) {
      this.tab = SocialProviders.Mail;
      this.subtitle = 'Email';
    }

    this.setSource(source);
  }

  public onClickBack() {
    this.showImageBrowser = false;
    this.tab = 'user-photos';
    this.isWide = false;
  }

  public onSidebarToggle() {
    this.toggleSidebar.emit();
  }
}
