import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
} from '@angular/router';
import { OrderService } from './order';
import {
  from,
  Observable,
} from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RoutingService implements Resolve<any> {

  constructor(private orderService: OrderService, private router: Router, public http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return from(this.orderService
      .getOrderId()
      .then(async (orderId) => {
        if (route.params.newOrderParam && route.params.newOrderParam.match(/^modern|polaroid|full$/)) {

          return this.orderService.getOrder(orderId)
            .then(() => {
              this.orderService.order.style = route.params.newOrderParam;
              return this.orderService.saveOrder();
            })
            .then(() => {
              return this.router.navigate(['/']);
            });
        }

        if (route.params.newOrderParam === 'new') {
          await this.orderService.createNewOrder();
          return from(this.router.navigate(['/']));

        }

          localStorage.setItem('orderId', route.params.newOrderParam);
          return from(this.orderService.setOrder(route.params.newOrderParam));
          // return 'stg';
      }));
  }
}
