import {
  Component,
  Input,
} from '@angular/core';
import { SettingsService } from '../../settings/settings.service';
import { OrderService } from '../../order';

@Component({
  selector: 'app-mini-preview',
  templateUrl: './mini-preview.component.html',
  styleUrls: ['./mini-preview.component.scss'],
})
export class MiniPreviewComponent {
  @Input()
  filename: string;

  @Input()
  settings: MiniPreviewSettings;

  constructor(private settingsService: SettingsService, private orderService: OrderService) {
  }

  public get photoSrc() {
    const
      width = 400,
      quality = 30;

    return `${this.settingsService.settings.apiUrl}/order/${this.orderService.orderId}/${this.filename}/${this.settings.showVariant}/${width}/${quality}`;
  }
}

export interface MiniPreviewSettings {
  showVariant: 'original' | 'cropped' | 'optimized';
}
