import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { InstagramService } from './instagram/instagram.service';

@Injectable({
  providedIn: 'root',
})
export class InstagramResolverService implements CanActivate {
  constructor(private instagramService: InstagramService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (route.queryParams.code) {
      this.instagramService.saveToken(route.queryParams.code)
        .then(() => {

          // window.close();
        })
        .catch((e) => {
          console.error(e);
        });
    }
    else {
      alert('no code!');
      window.close();
    }
    return;
  }
}
