import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  FacebookService,
  GooglePhotosService,
  InstagramService,
} from '.';

@NgModule({
  providers: [
    FacebookService,
    GooglePhotosService,
    InstagramService,
  ],
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class SocialConnectModule { }
