import {
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';
import {
  CropperPosition,
  ImageCropperComponent
} from 'ngx-image-cropper';

@Component({
  selector: 'app-modal-cropper',
  templateUrl: './modal-cropper.component.html',
  styleUrls: ['./modal-cropper.component.scss']
})
export class ModalCropperComponent {
  @ViewChild(ImageCropperComponent, { static: true }) imageCropper;

  public cropperPositionOriginal: CropperPosition;
  public cropperPosition: CropperPosition;
  public onClose: Subject<string> = new Subject();
  public originalImageBase64: string;
  public url: string;
  public urn: string;

  constructor(public bsModalRef: BsModalRef) {
    this.cropperPosition = {
      x1: 0,
      x2: 0,
      y1: 0,
      y2: 0,
    };
  }

  public imageCropped() {
    this.bsModalRef.hide();
  }

  public async onCropCancel() {
    this.onClose.next(null);
    this.bsModalRef.hide();
  }

  public onImageLoaded() {
    if (!this.cropperPositionOriginal) {
      return;
    }

    setTimeout(() => {
      this.cropperPosition = this.cropperPositionOriginal;
    }, 20);
  }

  public async onCropSave() {
    this.onClose.next(await this.imageCropper.crop());
  }
}
