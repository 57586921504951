import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';

@Injectable()
export class InstagramService {
  public media: Array<any>;
  private callbackUrls = {
    // redirect: 'https://rokreativa.si/',
    redirect: 'https://localhost:4200/instagram/redirect',
    deauthorize: 'https://localhost:4200/instagram/deauthorize',
    deleteData: 'https://localhost:4200/instagram/data-deletion-request',
  };

  get token(): string {
    return localStorage.getItem('token');
  }

  get code(): string {
    return localStorage.getItem('code');
  }

  constructor(private http: HttpClient, private api: ApiService) {
    // this.login();
  }

  // private get(endpoint: string) {
  //   return this.http.get(`https://api.instagram.com/v1${endpoint}?access_token=${this.token}`);
  // }

  // public async getToken(code?): Promise<string> {
  //   if (!code) {
  //     code = this.code;
  //   }
  //
  //   this.http.get(`https://api.instagram.com/oauth/authorize/?client_id=CLIENT-ID&redirect_uri=REDIRECT-URI&response_type=token`)
  // }

  public async saveToken(code: string): Promise<void> {

    try {
      localStorage.setItem('i_token', await this.api.getInstagramToken(code, this.callbackUrls.redirect));
    }
    catch (e) {
      console.error(e);
    }
  }

  public login() {
    const url = 'https://api.instagram.com/oauth/authorize' +
      `?client_id=210642603361471` +
      `&redirect_uri=${this.callbackUrls.redirect}` +
      `&scope=user_profile,user_media` +
      `&response_type=code`;

    try {
      window.open(url);
    }
    catch (er) {
      console.error(er);
    }
  }

  // public async getMedia(skip = 0, take = 20): Promise<any> {
  //   return this
  //     .get('/users/self/media/recent/')
  //     .toPromise();
  // }
  //
  // public async getUserData(): Promise<any> {
  //   return this
  //     .get('/users/self')
  //     .toPromise();
  // }
}
