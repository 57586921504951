import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  SettingsService,
} from '../../settings';

@Component({
  selector: 'sidebar-header',
  templateUrl: './sidebar-header.component.html',
  styleUrls: ['./sidebar-header.component.scss'],
})
export class SidebarHeaderComponent {
  constructor(public settingsService: SettingsService) {
  }

  @Output()
  public sidebarToggle = new EventEmitter<void>();

  @Input()
  public hamburgerVisible: boolean;
}
