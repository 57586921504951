import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstagramService } from './instagram.service';
import { HttpClientModule } from '@angular/common/http';
import { InstagramComponent } from './instagram.component';
import { InstagramCallbackComponent } from './instagram-callback.component';

@NgModule({
  declarations: [InstagramComponent, InstagramCallbackComponent],
  exports: [InstagramComponent, InstagramCallbackComponent],
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  providers: [InstagramService],
})
export class InstagramModule {}
