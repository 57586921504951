import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { UserInfo } from '../../models';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {
  @Input()
  public userInfo: UserInfo;

  constructor() { }

  ngOnInit() {

  }
}
