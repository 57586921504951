import { Injectable } from '@angular/core';
import {
  isNil,
  map,
} from 'lodash';
import { SettingsService } from '../settings';
import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import { ApiService } from '../api.service';
import {
  Album,
  Photo,
  UserInfo,
} from '../image-browsing/models';

@Injectable({
  providedIn: 'root',
})
export class GooglePhotosService {
  public albums: Array<any>;
  public albumPhotos: Array<any>;
  public isConnected = false;

  private userData: any;
  private accessTokenLocalStorageKey = 'gaccess';
  private intervalHandle: number;

  constructor(private settingsService: SettingsService,
              private http: HttpClient,
              private apiService: ApiService) {
    this.isConnected = !isNil(localStorage.getItem(this.accessTokenLocalStorageKey));
  }

  public async mapAlbums(albums?: any): Promise<Array<Album>> {
    return map(albums, (album) => {
      return {
        id: album.id,
        name: album.title,
        coverPhoto: `${album.coverPhotoBaseUrl}=w300-h300`,
      };
    });
  }

  public static mapAlbumPhotos(photos: any): Array<Photo> {
    return map(photos.mediaItems, (photo) => {
      return {
        id: photo.id,
        url: `${photo.baseUrl}=w300-h300-c`,
        originalUrl: `${photo.baseUrl}=d`
      };
    });
  }

  private static createOauthWindow(url: string,
                                   name = 'Authorization') {
    if (url == null) {
      return null;
    }

    return window.open(url, name);
  }

  public async getAlbums() {
    return (await this.get('albums?pageSize=50')).albums;
  }

  public async getAlbum(albumId: string): Promise<Array<Photo>> {
    this.albumPhotos = GooglePhotosService.mapAlbumPhotos(await this.post(`mediaItems:search`, {
      albumId,
      pageSize: '100',
    }));

    return this.albumPhotos;
  }

  public get userInfo(): UserInfo {
    return {
      name: this.userData.name,
      picture: this.userData.picture,
    };
  }

  public async getUser(): Promise<any> {
    this.userData = await this.get('https://www.googleapis.com/oauth2/v1/userinfo', true);
    return this.userData;
  }

  public async login(): Promise<any> {
    GooglePhotosService.createOauthWindow(`${this.settingsService.settings.apiUrl}/auth/google?callback=${this.settingsService.settings.apiUrl}/auth/callback/google`);

    return new Promise((resolve) => {
      this.intervalHandle = setInterval(() => {
        console.log('waiting for oAuth ...');
        if (localStorage.getItem('isgtokensaved') === '1') {
          localStorage.removeItem('isgtokensaved');
          this.isConnected = true;
          clearInterval(this.intervalHandle);

          resolve();
        }
      }, 1000);
    });
  }

  public async logout(): Promise<void> {
    localStorage.removeItem(this.accessTokenLocalStorageKey);
    localStorage.removeItem('grefresh');
    this.isConnected = false;

    return;
  }

  private async updateToken(): Promise<void> {
    const token = await this.apiService.refreshToken(localStorage.getItem('grefresh'), 'google');

    return localStorage.setItem(this.accessTokenLocalStorageKey, token);
  }

  private async get(method: string, fullUrl = false): Promise<any> {
    const token = localStorage.getItem(this.accessTokenLocalStorageKey);

    if (!token) {
      await this.login();
      return this.get(method, fullUrl);
    }

    let headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const url = fullUrl ? method : `https://photoslibrary.googleapis.com/v1/${method}`;

    return this.http.get(url, { headers })
      .toPromise()
      .catch(async (error) => {
        if (error.status === 401) { // token expired or no token
          try {
            await this.updateToken();

            return this.get(method);
          }
          catch (error) {
            console.error('error updating token.', error);
            return false;
          }
        }
      });
  }

  private async post(method: string, body): Promise<any> {
    const token = localStorage.getItem(this.accessTokenLocalStorageKey);

    if (!token) {
      await this.login();
      return this.get(method);
    }

    let headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.post(`https://photoslibrary.googleapis.com/v1/${method}`, body, { headers })
      .toPromise()
      .catch(async (error) => {
        if (error.status === 401) { // token expired
          try {
            await this.updateToken();

            return this.get(method);
          }
          catch (error) {
            console.error('error updating token.', error);
            return false;
          }
        }
      });
  }
}
